#react-sidebar .sidebar-main {
 right: 0;
 left: auto;
 transform: translateX(810px);
 overflow-y: scroll;
 top: 56px;
}

.sidebar-main {
  -webkit-overflow-scrolling: touch;
}

#react-sidebar .sidebar-backdrop {
  background-color: rgba(0, 0, 0, 0.40);
}

.sidebar-main-content ul li {
  font-size: 17px;
  padding: 1.0rem 1.5rem 1.0rem 1.5rem;
  border-top: 1px solid rgba(0,0,0,0.05);
  border-bottom: 1px solid rgba(0,0,0,0.05);
}

.sidebar-main-content ul:last-child {
  margin-bottom: 10px
}

#react-sidebar .section-heading {
  font-size: 17px;
  font-weight: 500;
  padding: 1.3rem 0.5rem 0.5rem 1.5rem;
  text-transform: capitalize;
}

.first-back-btn {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.second-back-btn {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.first-back-btn span,.second-back-btn span{
  vertical-align: middle;
}

.first-back-btn span,.second-back-btn span{
  margin-right: 32px;
}
