@font-face {
  font-family: "Poppins";
  font-weight: 300;
  font-style: normal;
  src: url("Poppins-Light.ttf");
}

@font-face {
  font-family: "Poppins";
  font-weight: 300;
  font-style: italic;
  src: url("Poppins-LightItalic.ttf");
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  src: url("Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: italic;
  src: url("Poppins-Italic.ttf");
}

@font-face {
  font-family: "Poppins";
  font-weight: 600;
  font-style: normal;
  src: url("Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  src: url("Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: italic;
  src: url("Poppins-BoldItalic.ttf");
}
