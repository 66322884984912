@font-face {
  font-family: "Sofia Pro";
  font-weight: 200;
  font-style: normal;
  src: url("Sofia Pro ExtraLight Az.otf");
}

@font-face {
  font-family: "Sofia Pro";
  font-weight: 200;
  font-style: italic;
  src: url("Sofia Pro ExtraLight Italic Az.otf");
}

@font-face {
  font-family: "Sofia Pro";
  font-weight: 300;
  font-style: normal;
  src: url("Sofia Pro Light Az.otf");
}

@font-face {
  font-family: "Sofia Pro";
  font-weight: 300;
  font-style: italic;
  src: url("Sofia Pro Light Italic Az.otf");
}

@font-face {
  font-family: "Sofia Pro";
  font-weight: 400;
  font-style: normal;
  src: url("Sofia Pro Regular Az.otf");
}

@font-face {
  font-family: "Sofia Pro";
  font-weight: 400;
  font-style: italic;
  src: url("Sofia Pro Regular Italic Az.otf");
}
@font-face {
  font-family: "Sofia Pro";
  font-weight: 500;
  font-style: normal;
  src: url("Sofia Pro Medium Az.otf");
}

@font-face {
  font-family: "Sofia Pro";
  font-weight: 500;
  font-style: italic;
  src: url("Sofia Pro Medium Italic Az.otf");
}

@font-face {
  font-family: "Sofia Pro";
  font-weight: 600;
  font-style: normal;
  src: url("Sofia Pro Semi Bold Az.otf");
}

@font-face {
  font-family: "Sofia Pro";
  font-weight: 600;
  font-style: italic;
  src: url("Sofia Pro Semi Bold Italic Az.otf");
}

@font-face {
  font-family: "Sofia Pro";
  font-weight: 700;
  font-style: normal;
  src: url("Sofia Pro Bold Az.otf");
}

@font-face {
  font-family: "Sofia Pro";
  font-weight: 700;
  font-style: italic;
  src: url("Sofia Pro Bold Italic Az.otf");
}

/* @font-face {
  font-family: "Sofia Pro";
  font-weight: 900;
  font-style: normal;
  src: local("/assets/fonts/sofiapro/Sofia Pro Black Az.otf");
}

@font-face {
  font-family: "Sofia Pro";
  font-weight: 900;
  font-style: italic;
  src: local("/assets/fonts/sofiapro/Sofia Pro Black Italic Az.otf");
} */
