.menu-item {
  margin: 5px 0;
  user-select: none;
  cursor: pointer;
  border: none;
}

.menu-item-wrapper1, .menu-item-wrapper-desktop1 {
  padding: 0 10px;
}

.menu-item-wrapper1 img, .menu-item-wrapper-desktop1 img {
  height: 185px;
  width: 136px;
  object-fit: cover;
  border-radius: 10px;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.menu-item-wrapper1 img {
  height: 185px;
  width: 136px;
}

.menu-item-wrapper-desktop1 img {
  height: 285px;
  width: 200px;
}

.menu-item-wrapper1:last-child,.menu-item-wrapper-desktop1:last-child {
  bottom: 50%;
  position: absolute;
  padding: 0 15px;
}
