.horizontal-menu {
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  display: flex;
  background-color: white;
}

.menu-wrapper {
  overflow: unset !important;
  user-select: unset !important;
}

.menu-item {
  margin: 5px 0;
  user-select: none;
  cursor: pointer;
  border: none;
}

.menu-item p {
  font-family: "Poppins";
  display: table;
  margin-bottom: unset;
}

.menu-item-wrapper.active {
  margin-left: 8px;
}

.menu-item-wrapper-desktop.active {
  margin-left: -10px;
}

.menu-item-wrapper {
  padding: 0 8px !important;
  outline: none;
}

.menu-item-wrapper-desktop {
  padding: 0 10px;
  outline: none;
}

.menu-item-wrapper img,
.menu-item-wrapper-desktop img {
  object-fit: contain;
  object-position: bottom;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.menu-item-wrapper-desktop img {
  height: 415px !important;
  width: 290px !important;
  background: #fff;
}

.menu-item-wrapper:last-child,
.menu-item-wrapper-desktop:last-child {
  position: absolute;
  padding: 0 15px;
}

.scroll-menu-arrow {
  position: unset;
}

.scroll-menu-arrow--disabled {
  background-color: #cccccc;
  color: #666666;
}
